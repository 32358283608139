import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { DropdownItem, Button } from 'reactstrap';
import IconButton from './IconButton';
import ButtonIcon from './ButtonIcon';

const ActionButton = (props) => {
  const {
    buttonText,
    buttonType,
    buttonIcon,
    buttonColor,
    ...buttonProps
  } = props;

  if (buttonType === 'plain') {
    return (
      <button type="button" {...buttonProps}>
        {buttonText}
      </button>
    );
  }
  if (buttonType === 'dropdown-item') {
    return (
      <DropdownItem tag="a" {...buttonProps}>
        {buttonIcon}
        {buttonText}
      </DropdownItem>
    );
  }
  if (buttonType === 'link') {
    return <a {...buttonProps}>{buttonText}</a>;
  }
  if (buttonType === 'button-icon') {
    return (
      <ButtonIcon color="falcon-default" icon={buttonIcon} {...buttonProps}>
        {buttonText}
      </ButtonIcon>
    );
  }
  if (buttonType === 'icon-button') {
    return (
      <IconButton
        labelText={buttonText}
        color={buttonColor}
        buttonIcon={buttonIcon}
        {...buttonProps}
      />
    );
  }
  if (buttonType === 'icon') {
    return (
      <FontAwesomeIcon
        className="cursor-pointer"
        icon={buttonIcon}
        {...buttonProps}
      />
    );
  }
  return (
    <Button color="falcon-default" {...buttonProps}>
      {buttonText}
    </Button>
  );
};

ActionButton.defaultProps = {
  buttonType: 'button',
  className: '',
  buttonIcon: '',
  buttonText: '',
  buttonColor: 'falcon-default',
  onClick: () => {},
};

ActionButton.propTypes = {
  buttonType: PropTypes.oneOf([
    'plain',
    'dropdown-item',
    'button',
    'link',
    'button-icon',
    'icon',
    'icon-button',
  ]),
  buttonIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  buttonColor: PropTypes.string,
  onClick: PropTypes.func,
};

export default ActionButton;
