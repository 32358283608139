import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import ActionsSvg from '../../../css/icons/Actions';

const ActionMenu = (props) => {
  const {
    className,
    dropdownTag,
    children,
    inNavbar,
    direction,
    disabled,
    buttonClass,
    buttonText,
    buttonColor,
    spacing,
    container,
    menuClass,
    modalIsParentEl,
  } = props;

  const wrapClass = cx(className, spacing);
  const buttonClasses = cx(buttonClass, { disabled });
  const menuProps = {
    right: direction === 'right',
    className: `${menuClass} ${
      modalIsParentEl ? 'adapt-modal-reactstrap-menu' : ''
    }`,
  };
  if (container) {
    menuProps.container = container;
  }

  return (
    <UncontrolledDropdown
      className={wrapClass}
      tag={dropdownTag}
      direction={direction}
      inNavbar={inNavbar}
      disabled={disabled}
    >
      <DropdownToggle color={buttonColor} className={buttonClasses}>
        {buttonText || <ActionsSvg />}
      </DropdownToggle>
      <DropdownMenu {...menuProps}>{children}</DropdownMenu>
    </UncontrolledDropdown>
  );
};

ActionMenu.defaultProps = {
  inNavbar: true,
  children: null,
  className: 'dropdown-button-wrapper',
  direction: 'left',
  disabled: false,
  buttonClass: 'btn-reveal',
  menuClass: 'dropdown-menu-wrapper',
  buttonColor: 'link',
  buttonText: undefined,
  dropdownTag: 'div',
  spacing: '',
  modalIsParentEl: false,
  container: undefined,
};

ActionMenu.propTypes = {
  className: PropTypes.string,
  menuClass: PropTypes.string,
  children: PropTypes.node,
  inNavbar: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonClass: PropTypes.string,
  buttonColor: PropTypes.string,
  dropdownTag: PropTypes.string,
  spacing: PropTypes.string,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  container: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  modalIsParentEl: PropTypes.bool,
};

export default ActionMenu;
